<template>
  <div class="pt-1">
    <course-section-edit-modal
      :edit-course-section-active.sync="editCourseSectionActive"
      :course-section-data="editCourseSectionData"
      @refetch-data="refetchData"
    />

    <div class="content-px mb-1">
      <b-row>
        <b-col cols="12" sm="8" md="9">
          <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
            <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 28rem" />
            <course-list-filters
              :visibility-filter.sync="visibilityFilter"
              :status-filter.sync="statusFilter"
              :visibility-options="visibilityOptions"
              :status-options="statusOptions"
            />
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="3" class="d-flex align-items-center justify-content-end"></b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <div no-body class="mb-0 table-no-top">
      <b-table
        ref="refCourseListTable"
        class="position-relative mb-0"
        :items="fetchCourseSections"
        responsive
        :fields="tableColumnsList"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Course -->
        <template #cell(startDate)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem" v-if="data.item.course">
            <b-avatar
              size="36"
              :src="data.item.course.avatar"
              :text="avatarText(data.item.course.name)"
              :variant="`light-info`"
              :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
              rounded
            />
            <div class="d-flex flex-column" style="gap: 0">
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="font-weight-bold text-nowrap text-white mb-0"
                style="font-size: 1.25rem; line-height: 1.75rem"
              >
                {{ resolveCourseSectionName(data.item) }}
              </b-link>
              <b-link
                :to="{ name: 'apps-roster-course-section-view', params: { id: data.item.id } }"
                class="d-flex align-items-center text-muted"
              >
                <lucide-icon name="Book" style="width: 15px; height: 15px; margin-right: 3px" />
                <small style="font-size: 1.05rem; line-height: 1.35rem"> {{ data.item.course.name }}</small>
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Instructor -->
        <template #cell(instructor)="data">
          <div
            class="d-flex align-items-center cursor-pointer"
            v-if="data.item.instructor && data.item.instructor.id"
            @click="$router.push({ name: 'apps-users-view', params: { id: data.item.instructor.id } })"
            style="gap: 0.625rem"
          >
            <b-avatar
              size="32"
              :src="data.item.instructor.avatar"
              :text="avatarText(data.item.instructor.name)"
              :variant="`light-${resolveUserRoleVariant(data.item.instructor.role)}`"
            />
            <div class="d-flex flex-column" style="gap: 0">
              <div class="font-weight-bold text-nowrap text-white mb-0" style="line-height: 1.3rem">
                {{ data.item.instructor.name }}
              </div>
              <div class="d-flex align-items-center text-muted">
                <small class="text-muted">@{{ data.item.instructor.username }}</small>
              </div>
            </div>
          </div>
          <span v-else class="text-muted text-nowrap">No Instructor Assigned</span>
        </template>

        <!-- Column: Total Students -->
        <template #cell(profiles)="data">
          <div
            class="text-nowrap"
            :style="data.item.statistics.counts.byStatus.enrolled && 'cursor: help'"
            :id="`student-statuses-${data.item.id}`"
          >
            <span
              class="align-text-top text-capitalize"
              :class="data.item.statistics.counts.byStatus.enrolled ? 'text-white' : 'text-muted'"
              >{{
                data.item.statistics.counts.byStatus.enrolled
                  ? `${data.item.statistics.counts.byStatus.enrolled} Student${
                      data.item.statistics.counts.byStatus.enrolled !== 1 ? 's' : ''
                    }`
                  : 'No Students Enrolled'
              }}</span
            >
          </div>

          <student-list-statuses v-if="data.value" :target-id="`student-statuses-${data.item.id}`" :data="data.item.statistics" />
        </template>

        <!-- Column: Direct Students -->
        <template #cell(direct_profiles)="data">
          <div
            class="text-nowrap"
            :style="data.item.courseCohort.statistics.counts.byStatus.enrolled && 'cursor: help'"
            :id="`direct-student-statuses-${data.item.id}`"
          >
            <span
              class="align-text-top text-capitalize"
              :class="data.item.courseCohort.statistics.counts.byStatus.enrolled ? 'text-white' : 'text-muted'"
              >{{
                data.item.courseCohort.statistics.counts.byStatus.enrolled
                  ? `${data.item.courseCohort.statistics.counts.byStatus.enrolled} Direct`
                  : 'None'
              }}</span
            >
          </div>

          <student-list-statuses
            v-if="data.value"
            :target-id="`direct-student-statuses-${data.item.id}`"
            :data="data.item.courseCohort.statistics"
          />
        </template>

        <!-- Column: Classes -->
        <template #cell(classes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize" :class="data.value ? 'text-white' : 'text-muted'">{{
              data.value ? `${data.value} Class${data.value !== 1 ? 'es' : ''}` : `No Classes`
            }}</span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolveCourseSectionVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveCourseSectionStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item v-if="$can('roster.section.update')" @click="editCourseSection(data.item)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('roster.section.get')"
              @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>View</span>
                <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-50">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCourseSections"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <vue-context ref="courseSectionListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          v-if="$can('roster.section.update')"
          class="d-flex align-items-center justify-content-between"
          @click="editCourseSection(data.item)"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.section.get')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-roster-course-section-view', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import CourseListFilters from './CourseSectionListFilters.vue';
import useCourseSections from '../useCourseSections';
import rosterStoreModule from '../../../../../../store/roster/rosterStoreModule';
import moment from 'moment';
import router from '@/router';
import CourseSectionEditModal from '../edit/CourseSectionEditModal.vue';
import StudentListStatuses from '../../../pathways/cohorts/view/components/StudentListStatuses.vue';
import VueContext from 'vue-context';

export default {
  components: {
    CourseListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
    CourseSectionEditModal,
    StudentListStatuses,
    VueContext,
  },
  watch: {
    '$route.meta.kind'() {
      this.setFilterType();
    },
  },
  methods: {
    // archiveCourse(courseId) {
    //   this.$swal({
    //     title: 'Are you sure?',
    //     text: 'Are you sure you want to archive this course?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Remove',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(async (result) => {
    //     if (result.value) {
    //       store
    //         .dispatch('rosterStoreModule/updateCourseSection', {
    //           courseId,
    //           courseData: { status: 'archived' },
    //         })
    //         .then((response) => {
    //           this.refetchData();
    //         });
    //     }
    //   });
    // },
    onRowContextMenu(item, index, event) {
      this.$refs.courseSectionListMenu.open(event, { item });
      event.preventDefault();
    },
    editCourseSection(data) {
      this.editCourseSectionData = data;
      this.editCourseSectionActive = true;
    },
  },
  setup() {
    const editCourseSectionData = ref({});
    const editCourseSectionActive = ref(false);

    const setFilterType = () => {
      typeFilter.value = router.currentRoute.meta.kind;

      if (router.currentRoute.meta.kind === 'Upcoming') {
        isSortDirDesc.value = false;
      } else {
        isSortDirDesc.value = true;
      }
    };

    const {
      fetchCourseSections,
      tableColumnsList,
      perPage,
      currentPage,
      totalCourseSections,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,

      statusOptions,
      visibilityOptions,

      // UI
      resolveUserRoleVariant,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionName,

      typeFilter,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = useCourseSections();

    setFilterType();

    return {
      // Sidebar

      fetchCourseSections,
      tableColumnsList,
      perPage,
      currentPage,
      totalCourseSections,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,

      moment,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveCourseSectionVisibilityVariant,
      resolveCourseSectionStatusVariant,
      resolveCourseSectionName,

      typeFilter,

      visibilityOptions,
      statusOptions,

      // Extra Filters
      visibilityFilter,
      statusFilter,

      editCourseSectionActive,
      editCourseSectionData,

      setFilterType,
    };
  },
  metaInfo() {
    return {
      title: `Course Sections`,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
